








































import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
  computed: {
    ...mapGetters('product', ['getCurrentProduct'])
  },

  methods: {
    goNext() {
      this.$router.push({
        name: 'finances.refunds.consignations.searchCreditLine'
      })
    }
  }
})
